import { Box, GluestackUIProvider, Text } from '@gluestack-ui/themed';
import { StyleSheet, View } from 'react-native';
import dayjs from "dayjs";

const numberFormat = (value) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'USD'
  }).format(value);

const ComprobanteCard = ({ comprobante }) => {

  return (
    <Box
      margin={5}
      flexDirection="scolumn"
      borderWidth={1}
      borderColor="$borderDark700"
      sx={{
        _web: {
          flex: 1,
        },
      }}

      rounded="$md"
      width={300}
      alignItems="center" // Add this line
    >

      <Box
        borderTopWidth={1}
        borderTopColor="$borderDark700"
        borderStartStartRadius={5}
        borderStartEndRadius={5}
        width={300}
        backgroundColor='rgba(185, 185, 185, 0.2)'
        alignItems="center" // Add this line
      >
        <Text fontSize={20} color="$white" fontWeight="500" ml="$2">
          <Text color="$blue500"> Descargar </Text>
        </Text>
      </Box>

      <Box mt={5}>
        <View style={styles.itemContainer}>
          <Text style={[styles.fecha]}>{comprobante.nombre}</Text>
        </View>

       
      </Box>

    </Box>
  );
};

export default ComprobanteCard;


const styles = StyleSheet.create({

  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  titulo: {
    color: 'white',
    marginRight: 10,
  },
  fecha: {
    color: 'white',
    fontSize: 14,
  },
  negrita: {
    fontWeight: 'bold',
  },

});
