import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
import { AxiosContext } from './context/AxiosContext';

import Unidades from './pages/unidades';
import Unidad from './pages/unidad';
import AvisoPago from './pages/avisoPago';
import Expensas from './pages/expensas';
import Notificaciones from './pages/notificaciones';
import Proveedores from './pages/proveedores';
import Comprobantes from './pages/comprobantes';

const Home = () => {

  const [pageUnidades, setPageUnidades] = useState(true);
  const [pageUnidad, setPageUnidad] = useState(false);
  const [pageAvisoPago, setPageAvisoPago] = useState(false);
  const [pageNotificaciones, setPageNotificaciones] = useState(false);
  const [pageExpensas, setPageExpensas] = useState(false);
  const [pageProveedores, setPageProveedores] = useState(false);
  const [pageComprobantes, setPageComprobantes] = useState(false);

  const [comprobanteLiquidacionID, setComprobanteLiquidacionID] = useState(null);

  const setPage = (page) => {

    setPageUnidades(false);
    setPageUnidad(false);
    setPageAvisoPago(false);
    setPageNotificaciones(false);
    setPageExpensas(false);
    setPageProveedores(false);
    setPageComprobantes(false);

    switch (page) {
      case 'unidades':
        setPageUnidades(true);
        break;
      case 'unidad':
        setPageUnidad(true);
        break;
      case 'avisoPago':
        setPageAvisoPago(true);
        break;
      case 'notificaciones':
        setPageNotificaciones(true);
        break;
      case 'expensas':
        setPageExpensas(true);
        break;
        case 'proveedores':
          setPageProveedores(true);
          break;  
        case 'comprobantes':
          setPageComprobantes(true);
          break;
      default:
        setPageUnidades(true);
        break;
    }
  }


  if(pageUnidades){
    return (<Unidades setPage={setPage}/>)
  }
  if(pageUnidad){
    return (<Unidad setPage={setPage}/>)
  }
  if(pageAvisoPago){
    return (<AvisoPago setPage={setPage}/>)
  }
  if(pageNotificaciones){
    return (<Notificaciones setPage={setPage}/>)
  }
  if(pageExpensas){
    return (<Expensas setPage={setPage}/>)
  }
  if(pageProveedores){
    return (<Proveedores setPage={setPage} setComprobanteLiquidacionID={setComprobanteLiquidacionID} />)
  }
  if(pageComprobantes){
    return (<Comprobantes setPage={setPage} comprobanteLiquidacionID={comprobanteLiquidacionID} />)
  }

};


export default Home;