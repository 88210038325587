import { Platform } from 'react-native';

let pre = "https://";
if (Platform.OS === 'web') {
   pre = "https://";
} else {
   pre = "http://";
}

export default {
   apiURL: '.kavanaghcloud.com.ar/api',
   //apiURL: '.kavanaghcloud.test/api',
   pre: pre,
};